// eslint-disable-next-line no-unused-vars
import React from "react"


const NotFoundPage = () => {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line no-undef
    window.location = '/';
  }

  return null;
}

export default NotFoundPage
